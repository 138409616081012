// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_dynamodb_all_tables_region": "ap-south-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "agendaFeedback-dev",
            "region": "ap-south-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "awsAgenda",
            "endpoint": "https://9frbdllpyd.execute-api.ap-south-1.amazonaws.com/dev",
            "region": "ap-south-1"
        }
    ]
};


export default awsmobile;
